<template>
  <item-card additionalClass="divide-y divide-gray-200 dark:divide-gray-700">
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        {{ customer.name }}
      </h3>
    </template>
    <template v-slot:buttons>
      <template v-if="!displayFormForEdit('editCustomerDetails')">
        <Button icon="pencil" @click="showForm('editCustomerDetails')" />
      </template>
    </template>
    <template v-slot:content>
      <ShowCustomerDetails v-if="!displayFormForEdit('editCustomerDetails')" :customer="customer" />
      <EditCustomerDetails v-else :customer="customer" />
    </template>
    <template v-slot:additional>
      <CustomerBankDetails :customer="customer" />

      <template v-if="customer.type.id === 1">
        <CustomerDetailContact :customer="customer" />
      </template>

      <CustomerDetailNotes :customer="customer" />
    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"

import { defineAsyncComponent } from 'vue'

const ShowCustomerDetails = defineAsyncComponent(() =>
  import('@/components/Customer/CustomerDetails/ShowCustomerDetails'))
const EditCustomerDetails = defineAsyncComponent(() =>
  import('@/components/Customer/CustomerDetails/EditCustomerDetails'))
const CustomerBankDetails = defineAsyncComponent(() =>
  import('@/components/Customer/CustomerDetails/BankDetails/CustomerBankDetails'))
const CustomerDetailContact = defineAsyncComponent(() =>
  import('@/components/Customer/CustomerDetails/CustomerDetailContact/CustomerDetailContact'))
const CustomerDetailNotes = defineAsyncComponent(() =>
  import('@/components/Customer/CustomerDetails/Notes/CustomerDetailNotes'))

export default {
  name: "CustomerDetails",
  components: {
    ItemCard,
    ShowCustomerDetails,
    EditCustomerDetails,
    CustomerBankDetails,
    CustomerDetailContact,
    CustomerDetailNotes,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.$store.dispatch('removeAllFormsForDisplay')
  },
  data: () => ({
    showDetails: false,
  }),
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    showForm(formName) {
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayFormForEdit(formName) {
      return this.formsForDisplay.includes(formName)
    },
    changeShowDetails() {
      this.showDetails = !this.showDetails
    }
  }
}
</script>

<style>

</style>